import cogDemo1 from '../../images/cogDemo1.png';
import cogDemo4 from '../../images/cogDemo4.png';
import cogDemo6 from '../../images/cogDemo6.png';
import cogDemo7 from '../../images/cogDemo7.png';
import cogDemo8 from '../../images/cogDemo8.png';
import cogDemo9 from '../../images/cogDemo9.png';
import cogDemo10 from '../../images/cogDemo10.png';
import cogDemo12 from '../../images/cogDemo12.png';
import cogDemo13 from '../../images/cogDemo13.png';
import cogDemo14 from '../../images/cogDemo14.png';
import cogDemo16 from '../../images/cogDemo16.png';
import cogDemo17 from '../../images/cogDemo17.png';
import cogDemo19 from '../../images/cogDemo19.png';
import cogDemo21 from '../../images/cogDemo21.png';

export const cogstructImages = [
cogDemo1, cogDemo4, cogDemo6, cogDemo7, cogDemo8, cogDemo9, cogDemo10, cogDemo12, cogDemo13, cogDemo14, cogDemo16, cogDemo17, cogDemo19,  cogDemo21
];